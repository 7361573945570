// @import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";
@font-face {
  font-family: 'Advert Regular';
  src: url("../fonts/Advert.ttf") format("truetype");
}

$brand-color: #F936E5;
$brand-black: #000;
$font-brand: 'Advert Regular', sans-serif;

body.home {
  background-size: cover;
  background: url('/resources/assets/img/workspace.jpg') no-repeat center 50px fixed;
  background-attachment: scroll;
  -moz-background-size: 100%;
  -webkit-background-size: 100%;
  -o-background-size: 100%;
  background-size: 100%;
}

.navbar-default a.navbar-brand {
  color: $brand-black;
  font-family: $font-brand;
  font-size: 14px;
  line-height: 24px;
  text-transform: uppercase;
}

.page-template-thmlv-page-team-php .container {
  margin-top: 30px;
}

.ticket-body .fa-eye{
  cursor: pointer;
}

.content{
    word-wrap: break-word;
}

img{
  max-width:100%;
}

.choose-client {
  font-size: 12px;
  vertical-align: middle;
}

#showClientChoose{
  .alert.alert-warning {
    margin-top: 15px;
    font-size: 12px;
    padding: 5px 15px;
    margin-bottom: 10px;
  }
  .btn.btn-danger, .btn.btn-success{
    margin: 0 20px;
  }
  .current-client {
    margin-top: 15px;
    display: none;
  }
}
